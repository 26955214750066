import { createApp } from "vue";
import store from "./store/store";
import Toast from "vue-toastification";
// Import global components and directives
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import SideBar from "./components/UIComponents/SidebarPlugin";
import initProgress from "./progressbar";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import App from "./App.vue";

// Import routes
import router from "./routes/routes";

// Import lodash and util
import lodash from "lodash";
import util from "src/helpers/util";

// Import your SCSS files
import "./assets/sass/paper-dashboard.scss";
import "./assets/sass/element_variables.scss";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import "vue-toastification/dist/index.css";
import "./assets/sass/global.scss";

const app = createApp(App);
// Router setup

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.pageTitle) {
    document.title = 'Dashboard - ' + to.meta.pageTitle;
  }

  if (to.path === "/login") {
    if (!store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/");
  } else {
    if (store.getters.isAuthenticated) {
      next();
      return;
    } else if (to.path === "/vue-table") {
      next();
      return;
    }
    localStorage.setItem("redirect_url", location.href);
    next("/login");
  }
});
initProgress(router);

// Use Vue Router
app.use(router);
app.use(store);

// Use other plugins
app.use(GlobalDirectives);
app.use(GlobalComponents);

app.use(Toast, {
  position: "bottom-right",
  timeout: 1500,
});

app.use(SideBar, { sidebarLinks: store.state.sidebarLinks });

// locale.use(lang);

// Customize Vue
app.config.globalProperties.$_ = lodash;
app.config.globalProperties.window = window;
app.config.globalProperties.$util = util;

// Mount the app
app.mount("#app");

export default app;
