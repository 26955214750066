import axios from './../api';
import { buildQuery } from "../actions";

export default {
  namespaced: true,
  state: {
    events: [],
    categories: [],
    eventDetail: [],
    categoryDetail: [],
    addonDetail: [],
    eventSeasons: [],
    addons: [],
    config: [],
    email_templates: []
  },

  getters: {

  },

  mutations: {

    EVENTS(state, val) {
      state.events = val;
    },

    CATEGORIES(state, val) {
      state.categories = val;
    },

    ADDONS(state, val) {
      state.addons = val;
    },

    EVENT_DETAIL(state, val) {
      state.eventDetail = val;
    },

    CATEGORY_DETAIL(state, val) {
      state.categoryDetail = val;
    },

    ADDON_DETAIL(state, val) {
      state.addonDetail = val;
    },

    EVENT_SEASONS(state, val) {
      state.eventSeasons = val;
    },

    CONFIG(state, val) {
      state.config = val;
    },

    EMAIL_TEMPLATES(state, val) {
      state.email_templates = val;
    }

  },

  actions: {

    updateVat({commit}, data) {
        return new Promise((resolve, reject) => {
            axios
                .put(`/api/admin/tickets/${data.object_id}/vat`, data)
                .then(res => resolve(res.data))
                .catch(error => reject(error))
            })
    },

    confirmGroupOrder(ctx, data) {
        return new Promise((resolve, reject) => {
            axios
                .post('/api/admin/tickets/confirm-group-order', data)
                .then(response => resolve(response.data))
                .catch(error => reject(error))
        })
    },

    email_templates(ctx, payload) {
        let {commit, state} = ctx;
        axios.get('/api/admin/email_templates')
            .then(response => {
                commit('EMAIL_TEMPLATES', response.data.data);
            });
    },

    config(ctx, payload) {
      let {commit, state} = ctx;
      axios.get('/api/admin/events/config', { params: payload })
        .then(response => {
          commit('CONFIG', response.data.data);
        })
        .catch(error => {
          console.error('fetchEvents: ', error);
        });
    },

    async fetch(ctx, params) {
      try {
        return await axios.get('/api/admin/events' + buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    async fetchCategory(ctx, params) {
      try {
        return await axios.get('/api/admin/event-categories' + buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    async fetchAddon(ctx, params) {
      try {
        return await axios.get('/api/admin/event-addons' + buildQuery(params));
      } catch (error) {
        console.log(error);
      }
    },

    detail(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/events/' + id)
        .then(response => {
          commit('EVENT_DETAIL', response.data.data);
        })
        .catch(error => {
          console.error('fetchEventDetail: ', error);
        });
    },

    categoryDetail(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/event-categories/' + id)
        .then(response => {
          commit('CATEGORY_DETAIL', response.data.data);
        })
        .catch(error => {
          console.error('fetchCategoryDetail: ', error);
        });
    },

    addonDetail(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/event-addons/' + id)
        .then(response => {
          commit('ADDON_DETAIL', response.data.data);
        })
        .catch(error => {
          console.error('fetchAddonDetail: ', error);
        });
    },

    seasons(ctx, id) {
      let { commit, state } = ctx;
      axios.get('/api/admin/events/' + id + '/seasons')
        .then(response => {
          commit('EVENT_SEASONS', response.data.data);
        })
        .catch(error => {
          console.error('fetchSeasons: ', error);
        });
    },

    store(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/events', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    storeCategory(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/event-categories', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    storeAddon(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/event-addons', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    storeVariant(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/addon-variants', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    storeDiscountGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/event-discounts', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    storeEmailTemplate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/email_templates', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    storeExtendField(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/event-extend-fields', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    createOrUpdateSeason(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/events/seasons', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    createOrUpdatePrice(ctx, data) {
      console.log('store: ', data);
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/events/seasons/prices', data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    update(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/events/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateCategory(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/admin/event-categories/update', formData)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateAddon(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/event-addons/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateVariant(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/addon-variants/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateDiscountGroup(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/event-discounts/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateEmailTemplate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/email_templates/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    updateExtendField(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/admin/event-extend-fields/' + data.id, data)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    delete(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/events/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteCategory(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/event-categories/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteAddon(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/event-addons/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteDiscount(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/event-discounts/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteEmailTemplate(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/email_templates/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    },

    deleteSeason(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/api/admin/events/seasons/' + id)
          .then(response => resolve(response.data))
          .catch(error => reject(error))
      })
    }

  },
}
